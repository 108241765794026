import useAsync from "../../useAsync";

import * as reportApi from "../../../services/reportApi";
import useToken from "../../useToken";

export default function useDeletePlanAnalysisComment() {
  const token = useToken();

  const {
    loading: deletePlanAnalysisCommentLoading,
    error: deletePlanAnalysisCommentError,
    act: deletePlanAnalysisComment,
  } = useAsync(
    (data) => reportApi.deletePlanAnalysisComment(data, token),
    false
  );

  return {
    deletePlanAnalysisCommentLoading,
    deletePlanAnalysisCommentError,
    deletePlanAnalysisComment,
  };
}
