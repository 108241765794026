import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import {
  AiFillMinusCircle,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from "react-icons/ai";
import { IoAddCircleSharp } from "react-icons/io5";

import { Section } from "../../../../components/Section";
import { AddSubitem, Container, Divider, Edit, Form, Row } from "../styles";
import theme from "../../../../styles/theme";
import ReportInfo from "../../../../components/forms/report-form/ReportInfo";
import useVessel from "../../../../hooks/api/vessel/useVessel";
import toastUtils from "../../../../utils/toast-utils";
import useInspectionData from "../../../../hooks/api/inspection/useInspectionData";
import useInspectionDetailsByProduct from "../../../../hooks/api/inspection/useInspectionDetailsByProduct";
import reportUtils from "../../../../utils/report-utils";
import TextSelect from "../../../../components/forms/report-form/TextSelect";
import useProductByAcronym from "../../../../hooks/api/product/useProductByAcronym";
import useCompany from "../../../../hooks/api/company/useCompany";
import generalUtils from "../../../../utils/general-utils";
import enrollmentUtils from "../../../../utils/enrollment-utils";
import Loader from "../../../../components/Loader";
import { FormsWrapper, InfoTitle } from "./styles";
import usePlanAnalysis from "../../../../hooks/api/report/usePlanAnalysis";
import PlanAnalysisSummary from "../../../../components/forms/report-form/PlanAnalysisSummary";
import PlanAnalysisSection from "../../../../components/forms/report-form/PlanAnalysisSection";
import Button from "../../../../components/forms/report-form/Button";
import UserContext from "../../../../contexts/UserContext";
import Textarea from "../../../../components/forms/report-form/Textarea";
import Label from "../../../../components/forms/report-form/Label";
import DeleteItem from "../../../../components/forms/report-form/DeleteItem";
import useDeletePlanAnalysisComment from "../../../../hooks/api/report/useDeletePlanAnalysisComment";
import useSavePlanAnalysis from "../../../../hooks/api/report/useSavePlanAnalysis";
import usePlanAnalysisInfo from "../../../../hooks/api/report/usePlanAnalysisInfo";

export default function PlanAnalysis() {
  const { reportId } = useParams();
  const newReport = isNaN(reportId);
  const { userData } = useContext(UserContext);
  const { getProductByAcronym, productByAcronymLoading } =
    useProductByAcronym();
  const { getVessel, vesselLoading } = useVessel();
  const { getInspectionData, inspectionDataLoading } = useInspectionData();
  const { getInspectionDetailsByProduct, inspectionDetailsByProductLoading } =
    useInspectionDetailsByProduct();
  const { getPlanAnalysis, planAnalysisLoading } = usePlanAnalysis();
  const { getPlanAnalysisInfo, planAnalysisInfoLoading } =
    usePlanAnalysisInfo();
  const { getCompany, companyLoading } = useCompany();
  const { savePlanAnalysis, savePlanAnalysisLoading } = useSavePlanAnalysis();
  const navigate = useNavigate();
  const [analysisList, setAnalysisList] = useState([]);
  const [vesselInfo, setVesselInfo] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [vesselMainFeaturesInfo, setVesselMainFeaturesInfo] = useState([]);
  const [analysis, setAnalysis] = useState(reportUtils.emptyPlanAnalysisOption);
  const [analysisData, setAnalysisData] = useState(
    reportUtils.emptyPlanAnalysisData
  );
  const [form, setForm] = useState(reportUtils.emptyPlanAnalysisData);
  const [comments, setComments] = useState([]);
  const [title, setTitle] = useState("Relatórios/Análise de Planos");
  const [editing, setEditing] = useState(false);
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    loadAnalysisOptions();
    loadAnalysisInfo();
  }, []);

  useEffect(() => {
    loadVesselData();
    loadPlanAnalysisData();
  }, [analysis.analysisId]);

  useEffect(() => {
    setComments(analysisData.obsList);
  }, [analysisData.obsList]);

  async function loadAnalysisOptions() {
    if (!newReport) return;

    try {
      const product = await getProductByAcronym("ADP");

      const response = await getInspectionDetailsByProduct(product.id);

      if (response)
        setAnalysisList(
          response.map((item) => ({
            value: item.id,
            label: `${item.id} - ${item.product.acronym} ${item.vessel.name}(${item.company.name})`,
            analysisId: item.inspectionId,
            formId: item.formId,
          }))
        );

      setTitle(title + "/Novo Relatório");
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadAnalysisInfo() {
    if (newReport) return;

    try {
      const response = await getPlanAnalysisInfo(reportId);

      if (response) {
        const result = {
          value: response.id,
          label: `${response.id} - ${response.product.acronym} ${response.vessel.name}(${response.company.name})`,
          analysisId: response.inspectionId,
          formId: response.formId,
        };

        const analysisName = `${reportId} - ${response.vessel.name}(${response.company.name})`;

        setAnalysisList([result]);
        setAnalysis(result);
        setTitle(
          `${title
            .replace("/Novo Relatório", "")
            .replace(`/${analysisName}`, "")}/${analysisName}`
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadVesselData() {
    if (!analysis.analysisId) return;

    try {
      const analysisData = await getInspectionData(analysis.analysisId);

      const vesselData = await getVessel(analysisData.vessel.id);

      const companyData = await getCompany(vesselData.vessel.company.value);

      if (vesselData)
        setVesselInfo([
          { label: "Nome da Embarcação", value: vesselData.vessel.name },
          {
            label: "Tipo de Embarcação",
            value: vesselData.vessel.vesselType.label,
          },
          {
            label: "Tipo de Navegação",
            value: vesselData.vessel.navigationType.label || "N/A",
          },
          {
            label: "Atividade ou Serviço",
            value: vesselData.vessel.vesselActivityServiceType.label,
          },
          {
            label: "Tipo de Verificação",
            value: vesselData.vessel.verificationType.label || "N/I",
          },
          {
            label: "OR anterior",
            value: vesselData.vessel.previousOr || "N/I",
          },
          {
            label: "Número de Licença",
            value: vesselData.vessel.registration || "N/I",
          },
          {
            label: "Material do Casco",
            value: vesselData.vessel.hullMaterial || "N/I",
          },
          {
            label: "Propulsão",
            value: vesselData.vessel.propulsion
              ? "Com propulsão"
              : "Sem propulsão",
          },
          { label: "Ano de Construção", value: vesselData.vessel.year.label },
          {
            label: "Clase",
            value: vesselData.vessel.vesselClass.label || "N/I",
          },
          { label: "AB", value: vesselData.vessel.grossTonnage || "N/I" },
        ]);

      setCompanyInfo([
        { label: "Armador", value: companyData.company.tradeName },
        {
          label: "Nacionalidade",
          value:
            generalUtils.nationalitiesConvertion[vesselData.vessel.flag.label],
        },
        {
          label: "CPF/CNPJ",
          value:
            companyData.company.cnpj.length > 11
              ? enrollmentUtils.cnpjMask(companyData.company.cnpj)
              : enrollmentUtils.cpfMask(companyData.company.cnpj),
        },
      ]);

      setVesselMainFeaturesInfo([
        {
          label: "Comprimento Total",
          value: vesselData.vessel.totalLength || "N/I",
        },
        { label: "Boca", value: vesselData.vessel.bow || "N/I" },
        { label: "Pontal", value: vesselData.vessel.draught || "N/I" },
        { label: "Calado", value: vesselData.vessel.draft || "N/I" },
      ]);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadPlanAnalysisData() {
    if (!analysis.analysisId) return;

    try {
      const response = await getPlanAnalysis({
        reportId: newReport ? 0 : reportId,
        formId: analysis.formId,
        inspectionId: analysis.analysisId,
      });

      if (response) {
        setAnalysisData(response);
        setForm(response);

        if (
          userData.enrollmentId === response.coordinatorId ||
          userData.enrollmentId === response.inspectorId
        )
          setAllowed(true);
      }

      if (newReport) setEditing(true);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let items = [];

    form.sections.forEach((section) => {
      section.items.forEach((item) => {
        console.log(new Date(item.deadline));
        items.push({
          itemId: item.id,
          deadline: new Date(item.deadline),
          listItemAnswerId: item.listItemAnswerId,
        });
      });
    });

    const body = {
      reportId: form.reportId,
      inspectionId: form.inspectionId,
      inspectionDetailId: form.inspectionDetailId,
      items,
      obsList: comments,
    };

    try {
      const response = await savePlanAnalysis(body);

      setEditing(false);

      const analysisName = `${response.reportId} - ${response.vessel.name}(${response.company.name})`;

      setTitle(
        `${title
          .replace("/Novo Relatório", "")
          .replace(`/${analysisName}`, "")}/${analysisName}`
      );
      navigate(`/painel/relatorios/analisedeplanos/${response.reportId}`);

      toastUtils.toaster({
        message: "Informações salvas com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <FormsWrapper theme={theme}>
          <Form onSubmit={handleSubmit}>
            {productByAcronymLoading || inspectionDetailsByProductLoading ? (
              <Loader theme={theme} />
            ) : (
              <div>
                <h3>Análise</h3>
                <Row>
                  <span className="analysis no-label">
                    <TextSelect
                      id="analysis"
                      placeholder="Selecione..."
                      icon={HiOutlineDocumentSearch}
                      enabled={newReport}
                      list={analysisList}
                      selected={analysis}
                      required
                      form={analysis}
                      setForm={setAnalysis}
                      handleForm={({ value }) => setAnalysis(value)}
                    />
                  </span>
                </Row>
              </div>
            )}

            {analysis.analysisId !== 0 && (
              <>
                {vesselLoading ||
                companyLoading ||
                inspectionDataLoading ||
                planAnalysisInfoLoading ||
                planAnalysisLoading ? (
                  <Loader theme={theme} />
                ) : (
                  <>
                    <Divider theme={theme} />

                    <InfoSection
                      info={vesselInfo}
                      title="Dados da Embarcação"
                    />

                    <Divider theme={theme} />

                    <InfoSection info={companyInfo} title="Armador" />

                    <Divider theme={theme} />

                    <InfoSection
                      info={vesselMainFeaturesInfo}
                      title="Características Principais da Embarcação"
                    />
                  </>
                )}

                <Divider theme={theme}></Divider>

                {!newReport && allowed && (
                  <Edit
                    theme={theme}
                    onClick={(e) => {
                      e.preventDefault();

                      if (editing) setForm(analysisData);
                      else if (!allowed) {
                        toastUtils.toaster({
                          message:
                            "Você não tem permissão para editar os dados desta empresa!",
                          type: toastUtils.type.error,
                          position: toastUtils.position.topCenter,
                          theme: toastUtils.theme.colored,
                        });

                        return;
                      }

                      setEditing(!editing);
                    }}
                  >
                    {editing ? "Cancelar" : "Editar"}
                  </Edit>
                )}

                <div>
                  <h3>Referências Utilizadas na Revisão</h3>

                  <PlanAnalysisSummary sections={form.summary} />
                </div>

                <Divider theme={theme}></Divider>

                <div>
                  <h3>Detalhes e Comentários do Documento</h3>

                  {form.sections.map((section, index) => (
                    <PlanAnalysisSection
                      key={index}
                      section={section}
                      enabled={editing && allowed}
                      setAnalysisData={setForm}
                    />
                  ))}
                </div>

                <Divider theme={theme}></Divider>

                <Button
                  className="submit"
                  title="Salvar"
                  enabled={editing && allowed && !savePlanAnalysisLoading}
                />
                {newReport && (
                  <Button
                    className="cancel"
                    title="Cancelar"
                    enabled={editing && allowed && !savePlanAnalysisLoading}
                    type="button"
                    onClick={() => navigate(-1)}
                  />
                )}

                <CommentSection
                  comments={comments}
                  setComments={setComments}
                  enabled={editing && allowed}
                />
              </>
            )}
          </Form>
        </FormsWrapper>
      </Container>
    </Section>
  );
}

function InfoSection({ info, title }) {
  const [display, setDisplay] = useState(false);
  return (
    <div>
      <InfoTitle theme={theme}>
        <h3>{title}</h3>
        {display ? (
          <AiOutlineCaretUp onClick={() => setDisplay(false)} />
        ) : (
          <AiOutlineCaretDown onClick={() => setDisplay(true)} />
        )}
      </InfoTitle>

      {display && <ReportInfo info={info} />}
    </div>
  );
}

function CommentSection({ comments, setComments, enabled }) {
  function handleAddComment() {
    setComments([
      ...comments,
      {
        id: 0,
        text: "",
      },
    ]);
  }

  return (
    <div>
      <h3>Observações</h3>
      {comments.map((comment, index) => (
        <CommentItem
          key={index}
          comment={comment}
          index={index}
          enabled={enabled}
          comments={comments}
          setComments={setComments}
        />
      ))}

      {enabled && (
        <AddSubitem
          theme={theme}
          disabled={!enabled}
          onClick={handleAddComment}
        >
          <IoAddCircleSharp />
          <p>Adicionar observação</p>
        </AddSubitem>
      )}
    </div>
  );
}

function CommentItem({ comment, index, enabled, comments, setComments }) {
  const { deletePlanAnalysisComment, deletePlanAnalysisCommentLoading } =
    useDeletePlanAnalysisComment();
  const [deleting, setDeleting] = useState(false);

  function handleChange({ index, value, name }) {
    let result = [...comments];

    result[index] = { ...result[index], [name]: value };

    setComments(result);
  }

  return (
    <div>
      <Row>
        <span className="textarea">
          {enabled && <AiFillMinusCircle onClick={() => setDeleting(true)} />}
          <Label id="text" text={`Comentário ${index + 1}`}></Label>
          <Textarea
            id="text"
            placeholder="Observação..."
            disabled={!enabled}
            value={comment.text}
            required
            onChange={(e) =>
              handleChange({
                index,
                name: e.target.id,
                value: e.target.value,
              })
            }
          />
        </span>
      </Row>

      {deleting && (
        <DeleteItem
          itemId={comment.id}
          text="Tem certeza que deseja excluir esta observação?"
          list={comments}
          setList={setComments}
          index={index}
          deleting={deleting}
          loading={deletePlanAnalysisCommentLoading}
          setDeleting={setDeleting}
          action={deletePlanAnalysisComment}
        />
      )}
    </div>
  );
}
