import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import toastUtils from "./toast-utils";

const states = [
  { label: "N/I", value: "N/I" },
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
];

const genderList = [
  { label: "M", value: "M" },
  { label: "F", value: "F" },
];

const emptyProfile = {
  user: {
    email: "",
    name: "",
    avatar: null,
  },
  enrollment: {
    permission: { label: "", value: 0 },
    department: { label: "", value: 0 },
    role: { label: "", value: 0 },
    roleObs: "",
    name: "",
    cpf: "",
    gender: { label: "", value: "" },
    birthDate: "",
    crea: "",
    creaUf: { label: "", value: 0 },
    phone: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    cep: "",
    uf: { label: "", value: 0 },
    city: "",
    neighborhood: "",
    street: "",
    number: "",
    complement: "",
  },
};

const emptyCompany = {
  cnpj: "",
  legalName: "",
  tradeName: "",
  municipalRegistration: "",
  stateRegistration: "",
  cep: "",
  uf: { label: "", value: "" },
  city: "",
  neighborhood: "",
  street: "",
  number: "",
  complement: "",
  qualitySystem: { label: "", value: "" },
  qualitySystemStandard: "",
  qualitySystemEntity: "",
  qualitySystemExpiration: "",
  typeId: 0,
  website: "",
  instagram: "",
  linkedin: "",
};

const emptyContact = {
  contactId: 0,
  type: { label: "", value: "" },
  email: "",
  contact: "",
  phone: "",
  extention: "",
  role: "",
  obs: "",
};

const emptyCredential = {
  credentialId: 0,
  link: "",
  username: "",
  password: "",
  obs: "",
};

const emptyVessel = {
  name: "",
  vesselType: { label: "", value: 0 },
  navigationType: { label: "", value: 0 },
  navigationArea: { label: "", value: 0 },
  vesselActivityServiceType: { label: "", value: 0 },
  registration: "",
  imo: "",
  company: { label: "", value: 0 },
  previousOr: "",
  registryPort: "",
  verificationType: { label: "", value: 0 },
  vesselClass: { label: "", value: 0 },
  year: { label: "", value: "" },
  flag: { label: "BRA", value: "BRA" },
  hullColor: "",
  hullMaterial: "",
  hullShape: "",
  superstructureColor: "",
  specializedEntity: "",
  deadweight: "",
  bow: "",
  grossTonnage: "",
  netTonnage: "",
  trim: "",
  draught: "",
  draft: "",
  loadedDraft: "",
  lightDraft: "",
  loadedDisplacement: "",
  lightDisplacement: "",
  propulsion: { label: "", value: "" },
  dockName: "",
  dockDoc: "",
  cep: "",
  uf: { label: "", value: "" },
  city: "",
  neighborhood: "",
  street: "",
  number: "",
  complement: "",
  callSign: "",
  totalLength: "",
  tonnageLength: "",
  waterlineLength: "",
  lbp: "",
  n: "",
  n1: "",
  n2: "",
  obs: "",
};

const emptyEngine = {
  engineId: 0,
  engineManufacturer: "",
  engineModel: "",
  engineNumber: "",
  enginePower: "",
  engineYear: { label: "", value: "" },
};

const companyOrderList = [
  { label: "Ordenar por...", value: "" },
  { label: "Razão Social", value: "RAZSOC" },
  { label: "Nome fantasia", value: "NMFNT" },
  { label: "CNPJ", value: "CNPJ" },
  { label: "Data de criação", value: "CRIADO_EM" },
  { label: "Última edição", value: "ATUALIZADO_EM" },
];

const userOrderList = [
  { label: "Nome", value: "NOME" },
  { label: "Data de criação", value: "CRIADO_EM" },
  { label: "Última edição", value: "ATUALIZADO_EM" },
];

const userEmptyFilters = {
  departments: [{ value: 0, label: "TODOS" }],
  roles: [{ value: 0, label: "TODOS" }],
  permissions: [{ value: 0, label: "TODOS" }],
};

const vesselEmptyFilters = {
  companies: [{ value: 0, label: "TODOS" }],
  vesselTypes: [{ value: 0, label: "TODOS" }],
  navigationTypes: [{ value: 0, label: "TODOS" }],
  vesselActivityServiceTypes: [{ value: 0, label: "TODOS" }],
};

const userEmptyDisplayParams = {
  role: { label: "", value: 0 },
  permission: { label: "", value: 0 },
  department: { label: "", value: 0 },
  order: { label: "", value: 0 },
};

const vesselEmptyDisplayParams = {
  order: { label: "", value: 0 },
  company: { label: "", value: 0 },
  vesselType: { label: "", value: 0 },
  navigationType: { label: "", value: 0 },
  vesselActivityServiceType: { label: "", value: 0 },
};

const vesselOrderList = [
  { label: "Nome", value: "NMEMBARCACAO" },
  { label: "Número de inscrição", value: "NUMINSC" },
  { label: "Data de criação", value: "CRIADO_EM" },
  { label: "Última edição", value: "ATUALIZADO_EM" },
];

const productOrderList = [
  { label: "Ordenar por...", value: "" },
  { label: "Nome", value: "NMPROD" },
  { label: "Código", value: "CDPROD" },
];

const productEmptyFilters = {
  services: [{ value: 0, label: "TODOS" }],
};

const productEmptyDisplayParams = {
  order: { label: "", value: 0 },
  service: { label: "", value: 0 },
};

const emptyProduct = {
  id: "",
  acronym: "",
  name: "",
  activity: { label: "", value: 0 },
  service: { label: "", value: 0 },
};

function cpfMask(value) {
  if (!value) return "";
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero, adiciona um ponto antes do segundo
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um hifen e não deixa ser digitado mais nada
}

function phoneMask(value) {
  if (!value) return "";
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "($1) $2") // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona um espaço antes do segundo
    .replace(/(\d)(\d{4})$/, "$1-$2"); // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona um hifen antes do segundo
}

function cnpjMask(value) {
  if (!value) return "";
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona um ponto antes do segundo
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona um ponto antes do segundo
    .replace(/(\d{3})(\d{1,2})/, "$1/$2") // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona uma barra antes do segundo
    .replace(/(\d{4})(\d{1,2})/, "$1-$2") // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona um hifen antes do segundo
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um hifen e não deixa ser digitado mais nada
}

function cepMask(value) {
  if (!value) return "";
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d)/, "$1-$2"); // captura 2 grupos de numero, apos capturar o primeiro grupo, adiciona um hifen antes do segundo
}

function dateMask(value) {
  if (!value) return "";
  if (value.length >= 10) return value.substring(0, 10);
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1/$2") // captura 2 grupos de numero, adiciona uma barra antes do segundo
    .replace(/(\d{2})(\d)/, "$1/$2");
}

function numberMask(value) {
  if (!value) return "";
  return value.replace(/\D/g, ""); // substitui qualquer caracter que nao seja numero por nada
}

async function handleCep(e, form, setForm, getAddress) {
  const cep = cepMask(e.target.value);
  setForm({ ...form, cep: cepMask(cep) });

  if (numberUnmask(e.target.value).length >= 8) {
    try {
      const address = await getAddress(numberUnmask(cep));

      if (address.uf) {
        setForm({
          ...form,
          street: address.logradouro,
          city: address.cidade,
          neighborhood: address.bairro,
          uf: states.find((state) => state.value === address.uf),
          cep: cep,
        });
      } else {
        setForm({
          ...form,
          street: emptyCompany.street,
          city: emptyCompany.street,
          neighborhood: emptyCompany.neighborhood,
          uf: emptyCompany.uf,
          cep: cep,
        });
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }
}

function numberUnmask(value) {
  if (!value) return "";
  return value.replace(/\D/g, ""); // substitui qualquer caracter que nao seja numero por nada
}

function editIncomingProfile(data) {
  return {
    ...data,
    cpf: cpfMask(data.cpf),
    phone: phoneMask(data.phone),
    emergencyContactPhone: phoneMask(data.emergencyContactPhone),
    cep: cepMask(data.cep),
    complement: data.complement ? data.complement : "",
    birthDate: data.birthDate || null,
  };
}

function editIncomingCompany({ company, contactTypes, qualitySystemOptions }) {
  dayjs.locale("pt-br");

  return {
    ...company,
    cnpj: cnpjMask(company.cnpj),
    cep: cepMask(company.cep),
    complement: company.complement ? company.complement : "",
    typeId: company.typeId,
    qualitySystem: qualitySystemOptions.find(
      (option) => option.value === company.qualitySystem
    ),
    qualitySystemExpiration: company.qualitySystemExpiration
      ? dayjs(company.qualitySystemExpiration).format("DD/MM/YYYY")
      : "",
    contacts: company.contacts.map((contact) => ({
      ...contact,
      phone: phoneMask(contact.phone),
      type: contactTypes.find((type) => type.value === contact.type),
    })),
  };
}

const propulsionList = [
  { label: "Com Propulsão", value: true },
  { label: "Sem Propulsão", value: false },
];

function generateYearOptions() {
  const currentYear = new Date().getFullYear();
  const years = [
    { label: "N/I", value: "0000" },
    { value: currentYear.toString(), label: currentYear.toString() },
  ];
  for (let year = currentYear - 1; year >= 1900; year--) {
    years.push({ value: year.toString(), label: year.toString() });
  }
  return years;
}

function convertToDate(dateString) {
  const parts = dateString.split("/");
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

function convertFromDate(dateString) {
  const date = new Date(new Date(dateString).getTime() + 3 * 60 * 60 * 1000);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;

  return `${day}/${month}/${year}`;
}

const enrollmentUtils = {
  states,
  emptyProfile,
  emptyCompany,
  emptyVessel,
  companyOrderList,
  userOrderList,
  vesselOrderList,
  userEmptyFilters,
  vesselEmptyFilters,
  userEmptyDisplayParams,
  vesselEmptyDisplayParams,
  emptyContact,
  emptyCredential,
  emptyEngine,
  emptyProduct,
  genderList,
  propulsionList,
  productOrderList,
  productEmptyFilters,
  productEmptyDisplayParams,
  cpfMask,
  cnpjMask,
  handleCep,
  phoneMask,
  cepMask,
  numberUnmask,
  numberMask,
  editIncomingProfile,
  editIncomingCompany,
  generateYearOptions,
  dateMask,
  convertToDate,
  convertFromDate,
};

export default enrollmentUtils;
