import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MdOutlineFactCheck } from "react-icons/md";
import { FaCircleCheck, FaHelmetSafety } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { IoChevronForward } from "react-icons/io5";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Divider, FormsWrapper, Row, Form } from "../../styles";
import { ListItem } from "../../../styles";
import toastUtils from "../../../../../utils/toast-utils";
import useInspectionRequests from "../../../../../hooks/api/inspection/useInspectionRequests";
import Label from "../../../../../components/forms/inspection-form/Label";
import TextSelect from "../../../../../components/forms/inspection-form/TextSelect";
import {
  AssignedItem,
  ChecklistsWrapper,
  Container,
  SchedulesTitle,
} from "./styles";
import Loader from "../../../../../components/Loader";
import Select from "../../../../../components/forms/inspection-form/Select";
import ProductPicker from "../../../../../components/forms/inspection-form/ProductPicker";
import Button from "../../../../../components/forms/inspection-form/Button";
import Textarea from "../../../../../components/forms/inspection-form/Textarea";
import useInspectionHistory from "../../../../../hooks/api/inspection/useInspectionHistory";
import useDeleteDetails from "../../../../../hooks/api/inspection/useDeleteDetails";
import InterruptButton from "../../../../../components/forms/inspection-form/InterruptButton";
import NextButton from "../../../../../components/forms/inspection-form/NextButton";
import ConfirmationMessage from "../../../../../components/ConfirmationMessage";
import UserContext from "../../../../../contexts/UserContext";
import useInspectors from "../../../../../hooks/api/inspection/useInspectors";
import useInspectionData from "../../../../../hooks/api/inspection/useInspectionData";
import inspectionUtils from "../../../../../utils/inspection-utils";
import enrollmentUtils from "../../../../../utils/enrollment-utils";
import { Section } from "../../../../../components/Section";
import theme from "../../../../../styles/theme";
import AnalysisTimeline from "../../../../../components/forms/inspection-form/AnalysisTimeline";
import noPicProfile from "../../../../../assets/no-pic-profile.png";
import useAssignAnalysis from "../../../../../hooks/api/inspection/useAssignAnalysis";

export default function AssignedAnalysis() {
  const { inspectionId } = useParams();
  const [newInspection, setNewInspection] = useState(isNaN(inspectionId));
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const allowed =
    userData.department.label === "ADMINISTRATIVO" ||
    userData.department.label === "DIRETORIA" ||
    userData.permission.label === "APROVADOR";
  const { getInspectionRequests } = useInspectionRequests();
  const { getInspectors, inspectorsLoading } = useInspectors();
  const { getInspectionData, inspectionDataLoading } = useInspectionData();
  const { assignAnalysis, assignAnalysisLoading } = useAssignAnalysis();
  const { getInspectionHistory, inspectionHistoryLoading } =
    useInspectionHistory();
  const { deleteDetails } = useDeleteDetails();
  const [title, setTitle] = useState(
    "Certificados/Análises Documentais/Atribuídas"
  );
  const [editing, setEditing] = useState(false);
  const [inspection, setInspection] = useState(
    inspectionUtils.emptyInspectionData
  );
  const [requests, setRequests] = useState(inspectionUtils.emptyList);
  const [coordinators, setCoordinators] = useState(
    inspectionUtils.emptyInspector
  );
  const [inspectors, setInspectors] = useState([]);
  const [form, setForm] = useState(inspectionUtils.emptyAnalysisForm);
  const [schedules, setSchedules] = useState([]);
  const [displayAnalysis, setDisplayAnalysis] = useState(false);
  const [interrupting, setInterrupting] = useState(false);
  const [submiting, setSubmiting] = useState(false);

  useEffect(() => {
    setNewInspection(isNaN(inspectionId));
  }, [inspectionId]);

  useEffect(() => {
    loadRequests();

    if (!newInspection) {
      loadInspectionData();
      loadSchedules();
    }
  }, []);

  useEffect(() => {
    if (form.request.value && newInspection) {
      loadInspectionData();
      loadCoordinators();
      setForm({
        ...inspectionUtils.emptyInspectionForm,
        request: form.request,
      });
    }
  }, [form.request.value]);

  async function loadInspectionData() {
    try {
      const response = await getInspectionData(
        newInspection ? form.request.value : inspectionId
      );

      if (response) {
        setInspection(response);
        setForm({
          ...form,
          obs: response.obs,
          coordinator: {
            label: response.coordinator.name,
            value: response.coordinator.id,
          },
        });

        if (!newInspection) {
          setForm({
            ...form,
            request: response.request,
            obs: response.obs,
            coordinator: {
              label: response.coordinator.name,
              value: response.coordinator.id,
            },
          });

          setTitle(
            `${title}/${response.id} - ${response.vessel.name} (${response.company.name})`
          );
        }
      } else {
        setForm(inspectionUtils.emptyInspectionForm);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadRequests() {
    if (newInspection) {
      setTitle(`${title}/Nova Análise`);
      setEditing(true);
    }

    try {
      const response = await getInspectionRequests();

      if (response) setRequests(requests.concat(response));
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadCoordinators() {
    let skip = 0;
    let take = 1000;

    try {
      const response = await getInspectors({
        skip,
        take,
      });
      if (response) {
        setCoordinators(
          inspectionUtils.emptyInspector.concat(
            response.map((inspector) => ({
              ...inspector,
              label: inspector.name,
              value: inspector.crea.id,
            }))
          )
        );
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadSchedules() {
    try {
      const response = await getInspectionHistory(inspectionId);

      if (response) setSchedules(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    if (name === "cpf")
      return setForm({ ...form, [name]: enrollmentUtils.cpfMask(value) });

    if (name === "phone" || name === "emergencyContactPhone")
      return setForm({ ...form, [name]: enrollmentUtils.phoneMask(value) });

    if (name === "number")
      return setForm({ ...form, [name]: value.toString() });

    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const body = {
      inspectionId: form.request.value,
      obs: form.obs,
      forms: form.reports.map((report) => ({
        detailId: report.detailId || 0,
        inspected: false,
        pendent: false,
        obs: report.obs,
        status: inspectionUtils.status.AGUARDANDO_ANALISE_VISTORIADOR,
        inspectorId: form.inspector.value,
        formId: report.reportId,
      })),
    };

    try {
      await assignAnalysis(body);

      navigate("/painel/certificados/analises-documentais/atribuidas");

      toastUtils.toaster({
        message: "Análise atribuída com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleInterrupt() {
    try {
      let det = [];

      [...schedules.slice(0, 0), ...schedules.slice(0 + 1)].forEach(
        (analysis) => {
          det = [...det, analysis.details];
        }
      );

      await deleteDetails({ inspectionId, det: det.join(",") });

      setInterrupting(false);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      <Container theme={theme}>
        <FormsWrapper>
          <Form className="inspectionForm" onSubmit={handleSubmit}>
            <div>
              <h3>Ordem de Serviço</h3>

              <Row>
                <span className="request no-label">
                  <TextSelect
                    id="request"
                    placeholder="Selecione..."
                    icon={MdOutlineFactCheck}
                    enabled={editing && allowed && newInspection}
                    list={requests}
                    selected={form.request}
                    required
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                  />
                </span>
              </Row>

              <Row>
                <span className="textarea no-label">
                  <Textarea
                    id="obs"
                    placeholder="Observações..."
                    disabled={!(editing && allowed)}
                    value={form.obs}
                    required
                    form={form}
                    onChange={(e) =>
                      handleForm({
                        name: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </span>
              </Row>
            </div>

            {form.request.value !== 0 && (
              <>
                {inspectorsLoading || inspectionDataLoading ? (
                  <Loader />
                ) : (
                  <AssignAnalysis
                    form={form}
                    setForm={setForm}
                    handleForm={handleForm}
                    enabled={editing && allowed}
                    coordinators={coordinators}
                    inspection={inspection}
                    inspectors={inspectors}
                    setInspectors={setInspectors}
                    newInspection={newInspection}
                  />
                )}
              </>
            )}

            {!newInspection && (
              <>
                <Divider theme={theme} />

                <SchedulesTitle theme={theme}>
                  <h3>Análises Atribuídas</h3>

                  {displayAnalysis ? (
                    <AiOutlineCaretUp
                      onClick={() => setDisplayAnalysis(false)}
                    />
                  ) : (
                    <AiOutlineCaretDown
                      onClick={() => setDisplayAnalysis(true)}
                    />
                  )}
                </SchedulesTitle>

                {displayAnalysis && (
                  <>
                    {inspectionHistoryLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {[
                          ...schedules.slice(0, 0),
                          ...schedules.slice(0 + 1),
                        ].map((analysis, index) => (
                          <span key={index}>
                            <ListItem
                              theme={theme}
                              onClick={() =>
                                navigate(
                                  `/painel/certificados/analises-documentais/atribuidas/detalhes/${inspectionId}?det=${analysis.details.join(
                                    ","
                                  )}`
                                )
                              }
                            >
                              <AssignedItem>
                                <span>
                                  <img
                                    src={
                                      analysis.inspector.avatar || noPicProfile
                                    }
                                    alt="Analista"
                                  ></img>
                                  <h5>{analysis.inspector.name}</h5>
                                </span>
                                <h6>
                                  {dayjs(analysis.createdAt)
                                    .locale("pt-br")
                                    .format("DD/MM/YYYY (HH:mm)")}
                                </h6>
                              </AssignedItem>
                            </ListItem>
                          </span>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Button
              className="submit"
              title="Atribuir"
              enabled={editing && allowed && !assignAnalysisLoading}
              onClick={handleSubmit}
            />
          </Form>

          {!newInspection && (
            <>
              <Divider theme={theme} />
              <AnalysisTimeline schedules={schedules} />
            </>
          )}
        </FormsWrapper>

        {!newInspection && (
          <>
            {allowed && (
              <InterruptButton
                text="Cancelar agendamento"
                setSubmiting={setInterrupting}
              />
            )}
            <NextButton
              text="Preencher checklists"
              setSubmiting={setSubmiting}
            />
          </>
        )}

        <ConfirmationMessage
          text="Tem certeza que deseja cancelar este agendamento?"
          action={handleInterrupt}
          confirming={interrupting}
          setConfirming={setInterrupting}
        ></ConfirmationMessage>

        <ConfirmationMessage
          text="Selecione um checklist para preencher"
          confirming={submiting}
          setConfirming={setSubmiting}
          okMessage={true}
        >
          <ChecklistsWrapper>
            {inspection.inProgressInspections.map((item, index) => (
              <Checklist
                key={index}
                id={item.report.id}
                name={item.report.name}
                obs={item.report.formObs}
                inspectionId={inspectionId}
                isFilled={item.isFilled}
                coordinator={inspection.coordinator}
                inspector={item.inspector}
              />
            ))}
          </ChecklistsWrapper>
        </ConfirmationMessage>
      </Container>
    </Section>
  );
}

function AssignAnalysis({
  form,
  setForm,
  handleForm,
  enabled,
  coordinators,
  inspection,
  inspectors,
  setInspectors,
  newInspection,
}) {
  const [checked, setChecked] = useState({});

  useEffect(() => {
    setInspectors(
      inspectionUtils.emptyInspector.concat(
        coordinators.filter((coordinator) =>
          form.reports.every((report) =>
            coordinator.qualifications.some(
              (qualification) => report.productId === qualification.productId
            )
          )
        )
      )
    );
  }, [form.reports]);

  function handleCheck({ name, value, serviceId, productId, obs }) {
    let result = [...form.reports];

    if (checked[value]) {
      result = result.filter((report) => report.reportId !== value);

      setChecked({ ...checked, [value]: false });
    } else {
      result = [
        ...result,
        { reportId: value, reportName: name, serviceId, productId, obs },
      ];

      setChecked({ ...checked, [value]: name });
    }

    setForm({ ...form, reports: result });
  }

  return (
    <>
      {inspection.pendingInspections.length > 0 && (
        <>
          <Divider theme={theme} />
          <div>
            <h3>Serviço(s) Pendente(s)</h3>

            {inspection.pendingInspections.map((product, index) => (
              <ProductPicker
                key={index}
                label={`${product.product.name} - ${product.report.name}`}
                id={product.report.id}
                form={form}
                setForm={setForm}
                checked={checked}
                handleCheck={handleCheck}
                handleForm={handleForm}
                serviceId={product.serviceId}
                productId={product.product.id}
                disabled={!enabled}
                obs={product.obs}
              />
            ))}
          </div>
        </>
      )}

      {newInspection && (
        <>
          <Divider theme={theme} />
          <div>
            <h3>Responsáveis Técnicos</h3>

            <Row className="responsible">
              <span className="inspector">
                <Label id="inspector" text="Analista*" />
                <Select
                  id="inspector"
                  placeholder="Selecione..."
                  icon={FaHelmetSafety}
                  enabled={enabled && form.reports.length > 0}
                  list={inspectors}
                  selected={form.inspector}
                  required
                  form={form}
                  setForm={setForm}
                  handleForm={handleForm}
                />
              </span>

              <span className="coordinator">
                <Label id="coordinator" text="Coordenador" />
                <Select
                  id="coordinator"
                  placeholder="Selecione..."
                  icon={FiUser}
                  enabled={false}
                  list={coordinators}
                  selected={form.coordinator}
                  required
                  form={form}
                  setForm={setForm}
                  handleForm={handleForm}
                />
              </span>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

function Checklist({
  id,
  name,
  obs,
  inspectionId,
  isFilled,
  coordinator,
  inspector,
}) {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  return (
    <ListItem
      theme={theme}
      onClick={() => {
        if (
          userData.enrollmentId !== coordinator.id &&
          userData.enrollmentId !== inspector.id
        )
          return toastUtils.toaster({
            message:
              "Apenas o analista ou o coordenador responsáveis pelo serviço podem preencher o checklist.",
            type: toastUtils.type.error,
            position: toastUtils.position.topCenter,
            theme: toastUtils.theme.colored,
          });

        return navigate(
          `/painel/preencher-checklist/${inspectionId}?formId=${id}&new=${
            isFilled ? "false" : "true"
          }&type=${inspectionUtils.inspectionTypes.analysis}`
        );
      }}
    >
      <div>
        <h5>{`${name} | ${obs}`}</h5>
        {isFilled ? <FaCircleCheck /> : <IoChevronForward />}
      </div>
    </ListItem>
  );
}
