import "./index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import SignIn from "./pages/authentication/SignIn";
import SignUp from "./pages/authentication/SignUp";
import SignUpForm from "./pages/authentication/SignUp/SignUpForm";
import ConfirmationMessage from "./pages/authentication/SignUp/ConfirmationMessage";
import ChangePassword from "./pages/authentication/ChangePassword";
import Home from "./pages/Home";

import { UserProvider } from "./contexts/UserContext";
import { EnrollmentProvider } from "./contexts/EnrollmentContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import useToken from "./hooks/useToken";
import AccountActivation from "./pages/authentication/SignUp/AccountActivation";
import EmailVerification from "./pages/authentication/ChangePassword/EmailVerification";
import NewPassword from "./pages/authentication/ChangePassword/NewPassword";
import ServicesUnderReview from "./pages/Home/Services/ServicesUnderReview";
import Services from "./pages/Home/Services";
import Calendar from "./pages/Home/Calendar";
import RequestedServices from "./pages/Home/Services/RequestedServices";
import Reports from "./pages/Home/Reports";
import Profile from "./pages/Home/Enrollment/Profile";
import Profiles from "./pages/Home/Enrollment/Profiles";
import Commercial from "./pages/Home/Commercial";
import Enrollment from "./pages/Home/Enrollment";
import Vessels from "./pages/Home/Enrollment/Vessels";
import Vessel from "./pages/Home/Enrollment/Vessel";
import Equipments from "./pages/Home/Enrollment/Equipments";
import Clients from "./pages/Home/Enrollment/Clients";
import Client from "./pages/Home/Enrollment/Company/Client";
import Suppliers from "./pages/Home/Enrollment/Suppliers";
import Supplier from "./pages/Home/Enrollment/Company/Supplier";
import AuthorizedServices from "./pages/Home/Services/AuthorizedServices";
import RequestedService from "./pages/Home/Services/RequestedService";
import Products from "./pages/Home/Enrollment/Products";
import Certifications from "./pages/Home/Enrollment/Products/Certifications";
import Certification from "./pages/Home/Enrollment/Products/Certification";
import Project from "./pages/Home/Enrollment/Products/Project";
import Projects from "./pages/Home/Enrollment/Products/Projects";
import ServiceUnderReview from "./pages/Home/Services/ServiceUnderReview";
import AuthorizedService from "./pages/Home/Services/AuthorizedService";
import { LocationsProvider } from "./contexts/locationsContext";
import InterruptedServices from "./pages/Home/Services/interruptedServices";
import InterruptedService from "./pages/Home/Services/InterruptedService";
import Certificates from "./pages/Home/Certificates";
import Dashboard from "./pages/Home/Dashboard";
import Inspectors from "./pages/Home/Certificates/Inspections/Inspectors";
import Inspector from "./pages/Home/Certificates/Inspections/Inspector";
import Inspections from "./pages/Home/Certificates/Inspections";
import ScheduledInspections from "./pages/Home/Certificates/Inspections/ScheduledInspections";
import PerformedInspections from "./pages/Home/Certificates/Inspections/PerformedInspections";
import ScheduledInspection from "./pages/Home/Certificates/Inspections/ScheduledInspection";
import ScheduleDetails from "./pages/Home/Certificates/Inspections/ScheduledInspection/ScheduleDetails";
import Checklists from "./pages/Home/Reports/Checklists";
import ConsultChecklist from "./pages/Home/Reports/ConsultChecklist";
import FillChecklist from "./pages/Home/Certificates/FillChecklist";
import DailyReport from "./pages/Home/Reports/DailyReport";
import DailyReports from "./pages/Home/Reports/DailyReports";
import DailyReportPdfViewer from "./pages/Home/Reports/DailyReportPdfViewer";
import Notifications from "./pages/Home/Notification/Notifications";
import Notification from "./pages/Home/Notification";
import PlanAnalyses from "./pages/Home/Reports/PlanAnalyses";
import PlanAnalysis from "./pages/Home/Reports/PlanAnalysis";
import DocumentAnalyses from "./pages/Home/Certificates/DocumentAnalyses";
import AssignedAnalyses from "./pages/Home/Certificates/DocumentAnalyses/AssignedAnalyses";
import AssignedAnalysis from "./pages/Home/Certificates/DocumentAnalyses/AssignedAnalysis";
import AnalysisDetails from "./pages/Home/Certificates/DocumentAnalyses/AssignedAnalysis/AnalysisDetails";
import { DpcCertificates } from "./pages/Home/DpcCertificates";
import DpcCertificate from "./pages/Home/DpcCertificates/DpcCertificate";

function App() {
  return (
    <UserProvider>
      <EnrollmentProvider>
        <LocationsProvider>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme
          />

          <div className="App">
            <Router>
              <Routes>
                <Route path="/" element={<SignIn />} />

                <Route path="/sign-up" element={<SignUp />}>
                  <Route path="" element={<SignUpForm />} />
                  <Route
                    path="confirmacao/:userId"
                    element={<ConfirmationMessage />}
                  />
                  <Route path="confirmacao" element={<AccountActivation />} />
                </Route>

                <Route path="/alterar-senha" element={<ChangePassword />}>
                  <Route path="email" element={<EmailVerification />} />
                  <Route path="nova-senha" element={<NewPassword />} />
                </Route>

                <Route
                  path="/painel"
                  element={
                    <ProtectedRouteGuard>
                      <Home />
                    </ProtectedRouteGuard>
                  }
                >
                  <Route path="inicio" element={<Dashboard />} />
                  <Route path="comercial" element={<Commercial />} />
                  <Route
                    path="comercial/:serviceId"
                    element={<RequestedService type={1} />}
                  />
                  <Route path="agenda" element={<Calendar />} />
                  <Route path="servicos" element={<Services />} />
                  <Route
                    path="servicos/solicitados"
                    element={<RequestedServices />}
                  />
                  <Route
                    path="servicos/analise"
                    element={<ServicesUnderReview />}
                  />
                  <Route
                    path="servicos/autorizados"
                    element={<AuthorizedServices />}
                  />
                  <Route
                    path="servicos/interrompidos"
                    element={<InterruptedServices />}
                  />
                  <Route
                    path="servicos/solicitados/:serviceId"
                    element={<RequestedService type={1} />}
                  />
                  <Route
                    path="servicos/analise/:serviceId"
                    element={<ServiceUnderReview type={2} />}
                  />
                  <Route
                    path="servicos/autorizados/:serviceId"
                    element={<AuthorizedService type={3} />}
                  />
                  <Route
                    path="servicos/interrompidos/:serviceId"
                    element={<InterruptedService />}
                  />
                  <Route path="cadastro" element={<Enrollment />} />
                  <Route
                    path="cadastro/clientes/:typeId"
                    element={<Clients />}
                  />
                  <Route
                    path="cadastro/fornecedores/:typeId"
                    element={<Suppliers />}
                  />
                  <Route
                    path="cadastro/cliente/:companyId"
                    element={<Client />}
                  />
                  <Route
                    path="cadastro/fornecedor/:companyId"
                    element={<Supplier />}
                  />
                  <Route path="cadastro/embarcacao" element={<Vessels />} />
                  <Route
                    path="cadastro/embarcacao/:vesselId"
                    element={<Vessel />}
                  />
                  <Route path="cadastro/equipamento" element={<Equipments />} />
                  <Route path="cadastro/produto" element={<Products />} />
                  <Route
                    path="cadastro/produto/certificacao"
                    element={<Certifications />}
                  />
                  <Route
                    path="cadastro/produto/projeto"
                    element={<Projects />}
                  />
                  <Route
                    path="cadastro/produto/certificacao/:productId"
                    element={<Certification />}
                  />
                  <Route
                    path="cadastro/produto/projeto/:productId"
                    element={<Project />}
                  />
                  <Route path="cadastro/perfil" element={<Profiles />} />
                  <Route path="cadastro/perfil/:userId" element={<Profile />} />
                  <Route path="perfil/:userId" element={<Profile />} />
                  <Route path="relatorios" element={<Reports />} />
                  <Route
                    path="relatorios/pdf-preview/:reportName"
                    element={<DailyReportPdfViewer />}
                  />
                  <Route
                    path="relatorios/checklists"
                    element={<Checklists />}
                  />
                  <Route
                    path="relatorios/checklists/:formId"
                    element={<ConsultChecklist />}
                  />
                  <Route path="relatorios/rdo" element={<DailyReports />} />
                  <Route
                    path="relatorios/rdo/:reportId"
                    element={<DailyReport />}
                  />
                  <Route
                    path="relatorios/analisedeplanos"
                    element={<PlanAnalyses />}
                  />
                  <Route
                    path="relatorios/analisedeplanos/:reportId"
                    element={<PlanAnalysis />}
                  />
                  <Route path="certificados" element={<Certificates />} />
                  <Route
                    path="certificados/vistorias"
                    element={<Inspections />}
                  />
                  <Route
                    path="certificados/vistorias/vistoriadores"
                    element={<Inspectors />}
                  />
                  <Route
                    path="certificados/vistorias/agendadas"
                    element={<ScheduledInspections />}
                  />
                  <Route
                    path="certificados/vistorias/realizadas"
                    element={<PerformedInspections />}
                  />
                  <Route
                    path="certificados/vistorias/vistoriadores/:inspectorId"
                    element={<Inspector />}
                  />
                  <Route
                    path="certificados/vistorias/agendadas/:inspectionId"
                    element={<ScheduledInspection />}
                  />
                  <Route
                    path="certificados/vistorias/agendadas/detalhes/:inspectionId"
                    element={<ScheduleDetails />}
                  />
                  <Route
                    path="certificados/analises-documentais"
                    element={<DocumentAnalyses />}
                  />
                  <Route
                    path="certificados/analises-documentais/atribuidas"
                    element={<AssignedAnalyses />}
                  />
                  <Route
                    path="certificados/analises-documentais/atribuidas/:inspectionId"
                    element={<AssignedAnalysis />}
                  />
                  <Route
                    path="certificados/analises-documentais/atribuidas/detalhes/:inspectionId"
                    element={<AnalysisDetails />}
                  />
                  <Route
                    path="preencher-checklist/:inspectionId"
                    element={<FillChecklist />}
                  />
                  <Route path="avisos" element={<Notifications />} />
                  <Route
                    path="avisos/:notificationId"
                    element={<Notification />}
                  />
                  <Route path="dpc/embarcacoes" element={<Vessels />} />
                  <Route
                    path="dpc/embarcacoes/:vesselId"
                    element={<Vessel />}
                  />
                  <Route
                    path="dpc/certificados"
                    element={<DpcCertificates />}
                  />
                  <Route
                    path="dpc/certificados/:certificateId"
                    element={<DpcCertificate />}
                  />
                </Route>
              </Routes>
            </Router>
          </div>
        </LocationsProvider>
      </EnrollmentProvider>
    </UserProvider>
  );
}

function ProtectedRouteGuard({ children }) {
  const token = useToken();

  if (!token) return <Navigate to="/" />;

  return <>{children}</>;
}

export default App;
