import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import theme from "../../../../styles/theme";
import { Container, Row } from "./styles";
import ReportDatePicker from "../ReportDatePicker";

export default function PlanAnalysisSection({
  section,
  enabled,
  setAnalysisData,
}) {
  let counter = 1;

  return (
    <Container theme={theme}>
      <Row className="title" theme={theme}>
        <span className="section">{`Seção ${section.sectionIndex}: ${section.name}`}</span>
      </Row>

      <Row className="subtitle" theme={theme}>
        <span className="index">Item</span>
        <span className="reference">Ref.</span>
        <span className="description">Comentário</span>
        <span className="date desktop">Ação Tomada</span>
        <span className="date mobile">A. Tom.</span>
        <span className="status desktop">Sitação</span>
        <span className="status mobile">Sit.</span>
      </Row>

      {section.items.map((item, index) => {
        return (
          <PlanAnalysisItem
            key={index}
            item={item}
            index={index}
            sectionIndex={section.sectionIndex}
            counter={counter++}
            enabled={enabled}
            setAnalysisData={setAnalysisData}
          />
        );
      })}
    </Container>
  );
}

function PlanAnalysisItem({
  item,
  index,
  sectionIndex,
  counter,
  enabled,
  setAnalysisData,
}) {
  const [deadline, setDeadline] = useState({
    date: item.deadline ? new Date(item.deadline) : null,
  });

  useEffect(() => {
    setAnalysisData((prevData) => ({
      ...prevData,
      sections: prevData.sections.map((section, i) => {
        if (i === sectionIndex.charCodeAt(0) - 65) {
          return {
            ...section,
            items: section.items.map((sectionItem, j) =>
              j === index
                ? { ...sectionItem, deadline: deadline.date }
                : sectionItem
            ),
          };
        }
        return section;
      }),
    }));
  }, [deadline]);

  return (
    <>
      <Row key={sectionIndex} theme={theme} enabled={enabled}>
        <span className="index">{`${sectionIndex}${counter}`}</span>
        <span className="reference">{item.reference || "-"}</span>
        <span className="description">
          <p>{item.comment}</p>
        </span>
        <span className="date">
          <ReportDatePicker
            id="date"
            type="date"
            disabled={!enabled}
            required
            value={
              deadline.date ? dayjs(deadline.date).format("DD/MM/YYYY") : null
            }
            form={deadline}
            setForm={setDeadline}
          />
        </span>
        <span className="status">{item.status.label}</span>
      </Row>
    </>
  );
}
