import { FaRegCalendar, FaRegEye } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";

import Select from "../../../components/forms/notification-form/Select";
import { Section } from "../../../components/Section";
import theme from "../../../styles/theme";
import {
  Container,
  DeleteButton,
  DeleteWrapper,
  NotificationContainter,
  ParamsWrapper,
} from "./styles";
import notificationUtils from "../../../utils/notification-utils";
import useNotifications from "../../../hooks/api/notification/useNotifications";
import useDeleteAllNotifications from "../../../hooks/api/notification/useDeleteAllNotifications";
import useDeleteNotification from "../../../hooks/api/notification/useDeleteNotification";
import toastUtils from "../../../utils/toast-utils";
import ListWrapper from "../../../components/ListWrapper";
import { ListItem } from "./styles";
import CheckBox from "../../../components/forms/notification-form/CheckBox";
import { Edit } from "../Reports/styles";
import ConfirmationMessage from "../../../components/ConfirmationMessage";

export default function Notifications() {
  const responseLength = 20;
  const { getNotifications, notificationsLoading } = useNotifications();
  const { deleteAllNotifications, deleteAllNotificationsLoading } =
    useDeleteAllNotifications();
  const { deleteNotification, deleteNotificationLoading } =
    useDeleteNotification();
  const [displayParams, setDisplayParams] = useState(
    notificationUtils.notificationEmptyDisplayParams
  );
  const [notifications, setNotifications] = useState([]);
  const [deleteList, setDeleteList] = useState({});
  const [deleteArray, setDeleteArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deletingAll, setDeletingAll] = useState(false);
  const [deletingSelected, setDeletingSelected] = useState(false);

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    reloadNotifications();
  }, [displayParams.period, displayParams.status]);

  useEffect(() => {
    setDeleteArray(
      Object.keys(deleteList).filter((key) => deleteList[key] === true)
    );
  }, [deleteList]);

  async function loadNotifications() {
    console.log("rodando");
    const params = {
      skip: notifications.length,
      take: responseLength,
      limit: displayParams.period.value || new Date().toISOString(),
      read: displayParams.status.value,
    };

    try {
      const response = await getNotifications(params);

      if (response) setNotifications(notifications.concat(response));
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function reloadNotifications() {
    const params = {
      skip: 0,
      take: responseLength,
      limit: displayParams.period.value || new Date().toISOString(),
      read: displayParams.status.value,
    };

    try {
      const response = await getNotifications(params);

      if (response) setNotifications(response);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value, form, setForm }) {
    setForm({ ...form, [name]: value });
  }

  function handleCheck(notificationId) {
    deleteList[notificationId]
      ? setDeleteList({ ...deleteList, [notificationId]: false })
      : setDeleteList({ ...deleteList, [notificationId]: true });
  }

  async function handleDeleteAllNotifications() {
    try {
      await deleteAllNotifications();

      setDeletingAll(false);
      setEditing(false);
      setDeleteList({});

      toastUtils.toaster({
        message: "Avisos excluídos com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      await reloadNotifications();
    } catch (error) {
      console.log(error);
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function handleDeleteSelectedNotifications() {
    const data = deleteArray.join(",");

    try {
      await deleteNotification(data);

      setDeletingSelected(false);
      setEditing(false);
      setDeleteList({});

      toastUtils.toaster({
        message: "Avisos excluídos com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      await reloadNotifications();
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title="Avisos" theme={theme}>
      <Container theme={theme}>
        <Edit
          theme={theme}
          onClick={(e) => {
            e.preventDefault();

            if (editing) setDeleteList({});

            setEditing(!editing);
          }}
        >
          {editing ? "Cancelar" : "Editar"}
        </Edit>

        <ParamsWrapper displayFilters={true}>
          <Select
            id="period"
            icon={FaRegCalendar}
            placeholder={"Período..."}
            enabled={true}
            list={notificationUtils.periodOptions}
            selected={displayParams.period}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />

          <Select
            id="status"
            icon={FaRegEye}
            placeholder={"Status..."}
            enabled={true}
            list={notificationUtils.statusOptions}
            selected={displayParams.status}
            form={displayParams}
            setForm={setDisplayParams}
            handleForm={handleForm}
            className="filter"
          />
        </ParamsWrapper>

        {editing && (
          <DeleteWrapper>
            <DeleteButton
              theme={theme}
              onClick={() => setDeletingSelected(true)}
              disabled={
                !editing ||
                deleteArray.length === 0 ||
                deleteNotificationLoading ||
                deleteAllNotificationsLoading
              }
            >
              <FaRegTrashAlt />
              {`Excluir selecionadas (${deleteArray.length || 0})`}
            </DeleteButton>

            <DeleteButton
              theme={theme}
              onClick={() => setDeletingAll(true)}
              disabled={
                !editing ||
                deleteAllNotificationsLoading ||
                deleteNotificationLoading
              }
            >
              <FaRegTrashAlt />
              Excluir tudo
            </DeleteButton>
          </DeleteWrapper>
        )}

        {notifications.length === 0 ? (
          <h4>Você não tem nenhum aviso </h4>
        ) : (
          <ListWrapper
            offset={responseLength}
            list={notifications}
            loadList={loadNotifications}
            loading={notificationsLoading}
          >
            {notifications.map((notification, index) => (
              <NotificationWrapper
                key={index}
                id={notification.notificationId}
                message={notification.message}
                read={notification.read}
                handleCheck={handleCheck}
                deleteList={deleteList}
                editing={editing}
                createdAt={notificationUtils.formatNotificationDate(
                  notification.createdAt
                )}
              />
            ))}
          </ListWrapper>
        )}

        <ConfirmationMessage
          text="Deseja mesmo excluir todos os avisos?"
          action={handleDeleteAllNotifications}
          confirming={deletingAll}
          setConfirming={setDeletingAll}
        ></ConfirmationMessage>

        <ConfirmationMessage
          text={`Deseja excluir os ${deleteArray.length} avisos selecionados?`}
          action={handleDeleteSelectedNotifications}
          confirming={deletingSelected}
          setConfirming={setDeletingSelected}
        ></ConfirmationMessage>
      </Container>
    </Section>
  );
}

function NotificationWrapper({
  id,
  message,
  read,
  handleCheck,
  deleteList,
  editing,
  createdAt,
}) {
  const navigate = useNavigate();

  return (
    <NotificationContainter>
      {editing && (
        <CheckBox
          label=""
          id={id}
          form={deleteList}
          handleCheck={handleCheck}
          disabled={!editing}
        />
      )}
      <ListItem theme={theme} read={read} onClick={() => navigate(`${id}`)}>
        <div>
          <p>{message}</p>
          <h6>{createdAt}</h6>
          <GoDotFill />
        </div>
      </ListItem>
    </NotificationContainter>
  );
}
