import theme from "../../../../styles/theme";
import { Container, Row } from "./styles";

export default function PlanAnalysisSummary({ sections }) {
  return (
    <Container theme={theme}>
      <Row className="title" theme={theme}>
        <span className="index">Item</span>
        <span className="description">Planos e Documentos Técnicos</span>
        <span className="review">REV.</span>
        <span className="status desktop">SITUAÇÃO</span>
        <span className="status mobile">SIT.</span>
      </Row>

      {sections.map((item, index) => {
        return (
          <ChecklistItem key={index} item={item} index={item.sectionIndex} />
        );
      })}
    </Container>
  );
}

function ChecklistItem({ item, index }) {
  return (
    <>
      <Row key={index} theme={theme} enabled={false}>
        <span className="index">{index}</span>
        <span className="description">
          <span>
            <p>{item.name}</p>
          </span>
        </span>
        <span className="review">{item.rev}</span>
        <span className="status">{item.status.label}</span>
      </Row>
    </>
  );
}
