import styled from "styled-components";

export const FormsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  margin-bottom: 100px;

  .textarea {
    height: 200px;

    svg {
      width: 18px;
      height: 18px;
      color: ${({ theme }) => theme.COLORS.GRAY_100};
      cursor: pointer;
      z-index: 2;
      position: absolute;
      left: 114px;
      top: 8px;
    }

    svg:hover {
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  .reverse {
    max-width: 140px;
    min-width: 140px;
  }

  @media (max-width: 1023px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const InfoTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin-bottom: 8px;
    cursor: pointer;
  }
`;
