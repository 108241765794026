import { useParams } from "react-router-dom";

import { Section } from "../../../components/Section";
import useDpcCertificate from "../../../hooks/api/dpc/useDpcCertificate";
import toastUtils from "../../../utils/toast-utils";
import { useEffect, useState } from "react";
import dpcUtils from "../../../utils/dpc-utils";
import PdfViewer from "../../../components/PdfViewer";
import Loader from "../../../components/Loader";

export default function DpcCertificate() {
  const { certificateId } = useParams();
  const { getDpcCertificate, dpcCertificateLoading } = useDpcCertificate();
  const [title, setTitle] = useState("Certificados");
  const [certificate, setCertificate] = useState(dpcUtils.emptyCertificate);

  useEffect(() => {
    loadCertificateData();
  }, []);

  async function loadCertificateData() {
    try {
      const response = await getDpcCertificate(certificateId);

      if (response) {
        setCertificate(response);
      }
      setTitle(`${title}/${response.name}`);
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Section title={title}>
      {dpcCertificateLoading ? (
        <Loader />
      ) : (
        <PdfViewer fileSource={certificate.fileSource} viewOnly={true} />
      )}
    </Section>
  );
}
