import { useState } from "react";
import theme from "../../../../styles/theme";
import { Textarea } from "./styles";

export default function ChecklistComment({
  answers,
  setAnswers,
  item,
  enabled,
  required,
}) {
  const [comment, setComment] = useState(answers[item.id].obs);

  function handleBlur() {
    setAnswers({
      ...answers,
      [item.id]: { ...answers[item.id], obs: comment },
    });
  }

  return (
    <Textarea
      theme={theme}
      type="text"
      placeholder="Detalhamento..."
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      onBlur={handleBlur}
      disabled={answers[item.id]?.status === 0 || !enabled}
      rows={4}
      required={required}
    />
  );
}
